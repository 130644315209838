@import "./styles/generic.scss";

.App-link {
  color: #61dafb;
}

.button {
  &.is-primary {
    background-color: #0080a3 !important;
  }
}

.has-text-gray-70 {
  color: #4c535c;
}

.has-text-gray-80 {
  color: #373d45;
}

.has-text-gray-90 {
  color: #24282e;
}

img.is-horizontal-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50%;
}
