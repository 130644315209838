.muted-msg-container {
  position: absolute;
  z-index: 2;
  bottom: 108px;
  left: 12px;
}

.toast-message {
  background-color: rgba(55, 61, 69, 0.8);
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  padding: 6.5px 8px;
  z-index: 3;

  &.vcenter {
    position: absolute;
    top: calc(50% - 20px);
  }

  &.hcenter {
    position: absolute;
    left: calc(50% - 100px);
  }
}

