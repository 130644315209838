.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #9299a2;
}

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
  border-width: 1.5px;
  border-radius: 1px;
}

