.video-container {
  height: 100%;
  width: 100%;
  position: relative;

  .video {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .video-status-bar {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 5px 10px;
    background-color: #4444449c;
    color: #ececec;
    opacity: 0;
    font-size: 10px;
    transition-property: top, bottom, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in;

    .btn-control {
      padding: 5px;
      margin: -5px;
      cursor: pointer;
      border-radius: 20px;

      &:hover {
        background-color: #444;
      }
    }
  }

  &:hover .video-status-bar {
    opacity: 100;
  }

  .error {
    color: red;
  }
}
