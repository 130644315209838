.focused-video {
  flex: 1 1 auto !important;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;

  .settings {
    display: none;
    position: absolute;
    right: 40px;
    bottom: 120px;
  }

  &:hover {
    .settings {
      display: flex;
    }
  }
}

