.waiting-room {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column !important;

  .avatar {
    width: 72px;
    height: 72px;
  }

  .purple-pill {
    background-color: #f0eef8;
    color: #4e38a6;
    font-size: 14px;
    border: none;
    padding: 4px 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
  }

  .green-pill {
    background-color: #d7f2e4;
    color: #108247;
    font-size: 14px;
    border: none;
    padding: 4px 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
  }
}
