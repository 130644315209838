.avatar {
  height: 44px;
  width: 44px;
  border-radius: 40px;
  overflow: hidden;
  // opacity: 0.4;

  .avatar-text {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0;
  }
}
