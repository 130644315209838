.ic-red-microphone-off {
  &::after {
    background: url('/assets/icons/24px/ic-microphone-off.svg');
    content: " ";
    position: absolute;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
  }

  width: 20px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.ic-white-microphone-off {
  &::after {
    background: url('/assets/icons/24px/ic-microphone-off-white.svg');
    content: " ";
    position: absolute;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
  }

  width: 20px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.ic-white-microphone-on {
  background: url('/assets/icons/24px/ic-microphone-on-white.png');
  content: " ";
  position: absolute;
  width: 14px;
  height: 19px;
}

.ic-video-on {
  background: url('/assets/icons/32px/ic-video-on.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-video-off {
  background: url('/assets/icons/32px/ic-video-off.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-video-off-white {
  background: url('/assets/icons/32px/ic-video-off-white.png');
  content: " ";
  position: absolute;
  width: 48px;
  height: 48px;
}

.ic-microphone-on {
  background: url('/assets/icons/32px/ic-microphone-on.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-microphone-off {
  background: url('/assets/icons/32px/ic-microphone-off.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-flip-camera {
  background: url('/assets/icons/32px/ic-flip-camera.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-share-screen {
  background: url('/assets/icons/32px/ic-share-screen.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-picture-in-picture {
  background: url('/assets/icons/32px/ic-picture-in-picture.svg');
  content: " ";
  position: absolute;
  width: 32px;
  height: 32px;
}

.ic-settings {
  background: url('/assets/icons/24px/ic-settings.svg');
  content: " ";
  position: absolute;
  width: 24px;
  height: 24px;
}

.ic-times {
  background: url('/assets/icons/24px/ic-times.png');
  content: " ";
  position: absolute;
  width: 14px;
  height: 14px;
}

