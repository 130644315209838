.waiting-participant {
  flex: 1 1 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

