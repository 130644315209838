#btn-settings {
  position: absolute;
  top: 13px;
  left: 12px;
}

#btn-leave {
  position: absolute;
  top: 13px;
  right: 12px;
}

.controls-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 12px;
}

.controls {
  height: 100%;

  .mini-video {
    width: 88px;
    height: 106px;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    bottom: 108px;
    right: 12px;
  }

  &.move-video-down {
    .mini-video {
      bottom: 12px;
    }
  }

  #control-bar {
    width: 281px;
    margin: auto;

    .call-control {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.count-btn-4 {
      width: 340px;
    }

    &.count-btn-5 {
      width: 408px;
    }

    &.count-btn-6 {
      width: 476px;
    }

    &.count-btn-4,
    &.count-btn-5,
    &.count-btn-6 {
      .call-control {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.mini-video-preview {
  background-color: #24282e !important;
}
