.sidebar-collapser-float {
  position: absolute;
  bottom: 15px;
  right: -80px;
  z-index: 100;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.5s;
  background-color: #00436f99;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #00436f;
    color: white;
    border: 1px solid #00436f;
  }

  &.collapsed {
    .arrow {
      font-size: 18px;
    }
  }
}
