$primary: #1fbbda;
$secondary: #18345a;
$success: hsl(141, 71%, 48%);
$info: hsl(204, 86%, 53%);
$warning: hsl(48, 100%, 67%);
$danger: hsl(348, 100%, 61%);
$light: hsl(0, 0%, 96%);
$dark: hsl(0, 0%, 21%);

$theme-colors: () !default;

$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

.icon-button {
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;

  .round-shadow {
    display: inline-block;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;

    &:hover {
      border-radius: 30px;
    }
    @each $color, $value in $theme-colors {
      &.round-shadow-#{$color} {
        &:hover {
          background-color: #{$value}33;
        }
      }
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tile {
  .icon-button {
    &.top-right {
      position: absolute;
      top: 0;
      right: 0;
      @each $color, $value in $theme-colors {
        .round-shadow-#{$color} {
          .circle {
            border: 1px solid #{$value};
            border-radius: 10px;
          }

          &:hover {
            .circle {
              border: none;
              border-radius: none;
            }
          }
        }
      }
    }
  }
}
