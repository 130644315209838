$icon-color-hover: #ccc;
$icon-color: #000;
$dropdown-color: #373d45;
$dropdown-bg-color: #fff;
$dropdown-hover-bg-color: #f0f2f3;
$dropdown-shadow: rgba(36, 40, 46, 0.2);
$color-active: #0080a3;

.tc-select {
  position: relative;

  .tc-selected {
    position: relative;
    padding: 2px;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.56;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da;

    &:hover {
      border-bottom-color: #889096;
    }
  }

  .tc-select-clear {
    right: 30px;
    width: 20px;
  }

  .tc-select-open {
    right: 10px;

    &::after {
      left: 5px;
    }
  }

  .tc-select-clear,
  .tc-select-open {
    position: absolute;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    color: $icon-color;

    &:hover {
      color: $icon-color-hover;
    }
  }

  .tc-select-label {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.7px;
    text-align: left;
    color: $icon-color;
    margin-bottom: 0;
    transition: top 0.1s linear;
  }
}

.tc-select-dropdown {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: $dropdown-bg-color;
  list-style: none;
  padding: 0;
  max-height: 320px;
  overflow-y: auto;
  border: 0.5px solid $dropdown-hover-bg-color;
  box-sizing: border-box;
  box-shadow: 0 2px 4px $dropdown-shadow;
  border-radius: 4px;

  li {
    padding: 14px 16px;
    color: $dropdown-color;

    &:hover {
      background-color: $dropdown-hover-bg-color;
    }

    &.active {
      color: $color-active;
    }
  }
}
