#env-banner {
  padding: 6px 20px;
  background-color: red;
  color: #fff;
  text-align: center;
  font-size: 12px;

  &.local {
    background-color: #00c14c;
  }

  &.development {
    background-color: #ffdd57;
  }

  &.uat {
    background-color: #f14668;
  }

  #env-name {
    font-weight: bold;
  }
}
