.microphone-with-volume {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  .volume-container {
    position: relative;
    bottom: 4px;
    width: 6px;
    height: 13px;
    overflow: hidden;
    z-index: 1;
    border-radius: 10px;

    .volume-level {
      background-color: #31ad6d;
      display: block;
      height: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  &.is-sm {
    .volume-container {
      bottom: 4px;
      width: 4.2px;
      height: 10px;
    }
  }
}

