.media {
  &.clinician-card {
    padding: 16px;
    background-color: #d3e8ec;
    border-radius: 8px;

    .media-left {
      .avatar {
        height: 64px;
        width: 64px;
      }
    }
  }
}
