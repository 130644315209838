.sidebar {
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 100vh;
  overflow: auto;

  .menu {
    font-size: 0.85rem;
    margin: 0.5rem !important;
    width: 200px;
  }

  .logo {
    width: 200px;
  }

  .menu-label {
    /* Gray-60 */
    color: #626a74;
  }

  &.sidebar-sm {
    .menu {
      width: 58px;
    }

    .menu-label {
      /* Gray-60 */
      color: #626a74;
    }

    .logo-collapse {
      width: 60px;
    }
  }

  .menu-list a {
    /* Gray-60 */
    color: #626a74;

    &:hover {
      background: #e2e4e8;
    }

    &.is-active {
      background-color: transparent;
      font-weight: bold;
    }
  }

  .no-link {
    padding: 0.5em 0.75em;
  }

  .btn-join-call {
    padding: 5px 10px 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px !important;
    line-height: 10px;
  }

  .btn-online-offline {
    padding: 5px 10px 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px !important;
    line-height: 10px;
  }

  hr {
    background-color: #626a74;
    height: 1px;
    margin: 1px;
  }
}
