$primary: #00bbdc;
$secondary: #17335b;
$white: #fff;
$button-color: #007cba;
$button-color-hover: #0090d9;
$light: #f8f8f9;
$warning: #e68842;
$danger: #c13c47;

$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);

@function hoverColor($color) {
  @return lighten($color, 10%);
}

@function activeColor($color) {
  @return darken($color, 10%);
}
