.modal-confirm {
  .title {
    font-size: 24px;
    font-weight: 500;
  }

  .modal-content {
    max-width: max-content;
  }

  .button {
    min-height: 48px;
    margin: 8px 4px;

    &.is-primary,
    &.is-danger,
    &.is-info,
    &.is-success,
    &.is-warning,
    &.is-link {
      min-width: 200px;
    }

    &.is-text {
      min-width: 120px;
    }
  }

  @media only screen and (min-width: 768px) {
    .card-footer {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .card-footer {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
  }
}

