.invite-url-input {
  font-size: 13px;
  padding: 19px 10px;
  color: #5e5e5e;
}

.box.patient-queue {
  padding: 0;
  margin-bottom: 10px;
}
