.call-control {
  width: 68px;
  height: 80px;

  .ctr-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    color: #fff;
  }

  .ctr-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: #282d34;
    border-radius: 16px;
    margin: auto;

    &:hover {
      background-color: #454b54;
    }

    &.is-sm {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }

  &.is-sm {
    width: 40px;
    height: 40px;

    .ctr-btn {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }
}

