.mic-test {
  width: 36px;
  height: 36px;
  border-radius: 40px;
  background-color: rgba(55, 61, 69, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-test {
  position: relative;

  .mini-video {
    width: 100%;
    height: 180px;
    position: relative;
    bottom: 0 !important;
    right: 0;
    border-radius: 4px;
  }

  .video-container {
    height: 180px !important;
  }

  .OTPublisherContainer {
    width: 100% !important;
    height: 180px !important;
  }

  .mic-test {
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 2;
  }
}

.modal.modal-full-screen .modal-content {
  background-color: rgba(255, 255, 255, 0);
}
