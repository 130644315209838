.es-alert-container {
  position: fixed;
  z-index: 10000;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.top {
    top: 60px;
    justify-content: flex-start;
  }

  &.bottom {
    bottom: 50px;
    justify-content: flex-end;
  }

  &.middle {
    top: 50%;
    transform: translate(-50%, 0);
  }

  &.right {
    right: 30px;
  }

  &.left {
    left: 30px;
  }

  &.center {
    left: 50%;
    transform: translate(0, -50%);

    &.middle {
      transform: translate(-50%, -50%);
    }
  }

  .es-alert {
    opacity: 1;
    transition: opacity 500ms ease 0s;
    background-color: rgba(55, 61, 69, 0.8);
    color: #fff;
    padding: 14px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    margin-bottom: 10px;
    position: relative;

    &.hide {
      opacity: 0;
    }
  }
}
