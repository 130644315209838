.button {
  &.is-primary {
    background: #007cba;
    border-radius: 4px;
    color: #fff;

    &:hover {
      background: #0090d9;
    }

    &:active {
      background: #00699e;
    }
  }

  &.is-secondary {
    border: 1px solid #007cba;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    color: #007cba;

    &:hover {
      background: #ebf5fa;
    }

    &:active {
      background: #007cba;
    }
  }

  &.is-danger {
    background-color: $danger;
    border-color: transparent;
    color: #fff;

    &:hover {
      background-color: hoverColor($danger);
    }

    &:active {
      background-color: activeColor($danger);
    }

    &.is-inverted {
      background-color: #fff;
      border-color: transparent;
      color: $danger;

      &:hover {
        color: #fff;
        background-color: $danger;
      }

      &:active {
        color: #fff;
        background-color: activeColor($danger);
      }
    }
  }

  &:disabled,
  &.is-disabled {
    background: #f0f2f3;
    color: #abb1b9;
  }
}
