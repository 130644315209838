.mini-videos {
  position: absolute;
  z-index: 2;
  left: calc(100% - 220px);
  padding: 20px 0;

  &:hover {
    background-color: #31313182;
    border-radius: 10px;
  }

  .mini-video {
    height: 127px !important;
    width: 200px !important;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;

    &.focused {
      border: 1px solid red;
    }
  }
}

